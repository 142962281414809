/* Dependencies */
import 'array-flat-polyfill';
import { createApp } from 'vue';
import router from './router';

// CSS
import './index.scss';

// Components
import App from './App.vue';

// Create the initial application.
createApp(App).use(router).mount('#app');
