/* Dependencies */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Partner Form',
    component: () => import('../views/PartnerFormView.vue'),
  },
  {
    path: '/thank-you',
    name: 'Thank You',
    component: () => import('../views/ThankYou.vue'),
  },
  // {
  //   name: 'Catch All Route',
  //   path: '/',
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
