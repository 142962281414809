
/* Dependencies */
import { Options, Vue } from 'vue-class-component';

/**
 * App Component
 */
@Options({
  components: {},
})
export default class App extends Vue {}
